<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="modal" :class="{'is-active': active}">
    <div class="modal-background" />
    <slot />
  </div>
</template>
