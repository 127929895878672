<script>
import Draggable from 'vuedraggable';
import { helpers, required, minLength } from 'vuelidate/lib/validators';
import { generateId } from '@shared/services/Utils';
import AppSimpleModal from '@shared/components/AppSimpleModal.vue';
import QuizQuestionMediaContent from '@shared/components/QuizQuestionMediaContent.vue';
import QuizMediaContentForm from '@app/components/QuizMediaContentForm.vue';
import QuizGenericAnswerField from '@app/components/QuizGenericAnswerField.vue';

export default {
  components: {
    Draggable,
    AppSimpleModal,
    QuizMediaContentForm,
    QuizGenericAnswerField,
    QuizQuestionMediaContent,
  },
  props: {
    errorClass: {
      type: String,
      default: 'has-error',
    },
    trainingItem: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      contentForm: null,
      trainingItemQuiz: this.value.slice(),
    };
  },
  methods: {
    addQuestion() {
      this.trainingItemQuiz.push({
        isOpen: true,
        question: '',
        description: '',
        content: [],
        feature_options: {
          type: 'multiple',
        },
        answers: [{
          _tempId: generateId(),
          answer: '',
          answer_reason: '',
          content: [],
          is_correct: true,
        }, {
          _tempId: generateId(),
          answer: '',
          answer_reason: '',
          content: [],
          is_correct: false,
        }],
      });

      this.$nextTick(() => {
        const questionIndex = this.trainingItemQuiz.length - 1;
        const question = this.trainingItemQuiz[questionIndex];
        const ref = this.$refs[`q${questionIndex}`][0];

        this.$emit('question-add', { ref, question });
      });
    },
    removeQuestion(q) {
      this.trainingItemQuiz = this.trainingItemQuiz.filter((v) => v !== q);
    },
    addAnswer(q) {
      q.answers.push({
        _tempId: generateId(),
        answer: '',
        answer_reason: '',
        content: [],
        is_correct: false,
      });
    },
    removeAnswer(question, answerIndex) {
      question.answers.splice(answerIndex, 1);
    },
    onTypeChange(q) {
      const firstCorrectAnswer = q.answers.find((v) => v.is_correct);

      q.answers = q.answers.map((v) => {
        if (firstCorrectAnswer && firstCorrectAnswer !== v) {
          v.is_correct = false;
        }

        return v;
      });
    },
    onCorrectAnswerChange(question, answerIndex) {
      if (question.feature_options.type === 'single') {
        question.answers = question.answers.map((v, index) => {
          if (index !== answerIndex) {
            v.is_correct = false;
          }

          return v;
        });
      }
    },
    onSubmit($event) {
      if (this.$v.trainingItemQuiz.$anyError) {
        $event.preventDefault();
      }
    },

    // content form
    openContentForm(type, question) {
      if (this.$store.getters['auth/hasPercentPlan']) {
        this.$showMessage.goPro(() => {
          this.$router.push({ name: 'plan', query: { onglet: 'vous' } });
        });
        return;
      }

      if (question.content.length > 0) {
        this.$buefy.dialog.alert({
          title: 'Veuillez supprimer le média précédent',
          message: `
            Une question ne peut avoir qu'un
            seul média qui lui est associé.
          `,
        });
        return;
      }

      this.contentForm = { type, question };
    },
    addContent(data) {
      if (!this.contentForm) {
        return;
      }

      this.contentForm.question.content = [{
        id: generateId(),
        type: this.contentForm.type,
        data,
      }];

      this.contentForm = null;
    },
    removeContent(question) {
      question.content = [];
    },
  },
  validations: {
    trainingItemQuiz: {
      $each: {
        question: { required },
        answers: {
          required,
          minLength: minLength(2),
          atLeastOneCorrect: (val) => (!helpers.req(val) || val.some((v) => v.is_correct)),
          $each: {
            answer: { required },
          },
        },
      },
    },
  },
  watch: {
    trainingItemQuiz: {
      deep: true,
      handler() {
        this.$emit('dirty', true);
        this.$emit('input', this.trainingItemQuiz);
      },
    },
    value(val) {
      if (val !== this.trainingItemQuiz) {
        this.trainingItemQuiz = val.slice();
      }

      if (this.trainingItemQuiz.length <= 0) {
        this.$v.$reset();
      }
    },
    '$v.$invalid': {
      immediate: true,
      handler($invalid) {
        this.$emit('validation', { $invalid });
      },
    },
  },
};
</script>

<template>
  <div class="qgecomp">
    <h1 class="title is-2 has-text-centered">
      {{ trainingItem.name }}
    </h1>
    <div
      v-if="trainingItem.description"
      class="content"
      v-html="trainingItem.description"
    />

    <div v-if="trainingItem.quiz_submissions_count > 0" class="box is-warning is-flex">
      <b-icon class="mr-3" icon="info" size="is-medium" />
      <div class="content">
        <p>
          <template v-if="trainingItem.quiz_submissions_count > 1">
            {{ trainingItem.quiz_submissions_count }} apprenants ont déjà répondu
          </template>
          <template v-else>
            Un apprenant a déjà répondu
          </template>

          à ce quiz, de ce fait, vous ne pouvez plus modifier les questions.
        </p>
        <p>
          <strong>Alternative : </strong> dupliquez le quiz et modifiez-le
          selon vos besoins. <br>
          Quant à celui-ci, vous pourrez le mettre en brouillon.
        </p>
      </div>
    </div>

    <form @submit="onSubmit">
      <RenderlessToggle
        v-for="(q, qk) in trainingItemQuiz"
        :key="qk"
        :open="!!q.isOpen"
        #default="{ isOpen, toggle }"
      >
        <fieldset
          :ref="`q${qk}`"
          :key="`q${qk}`"
          class="qgecomp_fieldset message mb-5 p-2"
          :class="{
            [`is-danger ${errorClass}`]: $v.trainingItemQuiz.$each[qk].$error,
            'is-primary': !$v.trainingItemQuiz.$each[qk].$error
          }"
          :disabled="trainingItem.quiz_submissions_count > 0">
          <div class="message-header">
            <a
              class="w-full cursor-pointer tdecoration-none"
              title="Cliquez pour cacher / afficher"
              @click.prevent="toggle"
            >
              <b-icon :icon="isOpen ? 'chevron-up' : 'chevron-down'" />
              Question #{{ qk + 1 }}
              <template v-if="q.question">
                (<span class="mw-200 w-auto has-text-clipped is-inline-block valign-sub">
                  {{ q.question }}
                </span>)
              </template>
            </a>
            <button
              type="button"
              class="delete"
              aria-label="delete"
              @click="removeQuestion(q)"
            />
          </div>
          <div v-show="isOpen" class="message-body has-background-white">
            <b-field
              label="Entrez une question"
              v-bind="$getErrorProps($v.trainingItemQuiz.$each[qk].question, ['required'])"
              :addons="false"
            >
              <b-field>
                <b-select v-model="q.feature_options.type" @input="onTypeChange(q)">
                  <option value="multiple">Choix multiple</option>
                  <option value="single">Choix unique</option>
                </b-select>
                <b-input
                  v-model="q.question"
                  maxlength="255"
                  expanded
                  @blur="$v.trainingItemQuiz.$each[qk].question.$touch()"
                />
                <div class="control">
                  <b-dropdown
                    :disabled="trainingItem.quiz_submissions_count > 0"
                    position="is-bottom-left"
                  >
                    <template #trigger>
                      <b-button icon-left="ellipsis-h" />
                    </template>
                    <b-dropdown-item
                      @click="openContentForm('image', q)"
                    >
                      <b-icon icon="image" />
                      Ajouter une image
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="openContentForm('video', q)"
                    >
                      <b-icon icon="video" />
                      Ajouter une vidéo
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="openContentForm('audio', q)"
                    >
                      <b-icon icon="microphone" />
                      Ajouter un audio
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-field>
              <p
                v-show="
                  !$v.trainingItemQuiz.$each[qk].answers.required
                  || !$v.trainingItemQuiz.$each[qk].answers.minLength
                  || !$v.trainingItemQuiz.$each[qk].answers.atLeastOneCorrect
                "
                class="help is-danger"
              >
                Il faut au moins deux choix de réponse, dont une marquée comme "bonne".
              </p>
            </b-field>
            <QuizQuestionMediaContent
              class="field has-text-centered has-background-light p-3 bradius-4"
              :question="q"
            >
              <template #delete>
                <b-button
                  class="has-text-danger pr-0"
                  type="is-text"
                  @click="removeContent(q)"
                >
                  Supprimer le média de cette question
                </b-button>
              </template>
            </QuizQuestionMediaContent>
            <div>
              <draggable
                v-model="q.answers"
                handle=".handle"
                :disabled="trainingItem.quiz_submissions_count > 0"
              >
                <QuizGenericAnswerField
                  v-for="(a, ak) in q.answers"
                  :key="a.id || a._tempId"
                  :trainingItem="trainingItem"
                  :question="q"
                  :questionIndex="qk"
                  :answer="a"
                  :answerIndex="ak"
                  :vAnswer="$v.trainingItemQuiz.$each[qk].answers.$each[ak].answer"
                  @update:answer="$set(q.answers, ak, $event);"
                  @correct-change="onCorrectAnswerChange(q, ak)"
                  @remove="removeAnswer(q, ak)"
                />
              </draggable>
            </div>
            <p class="buttons is-centered mt-10">
              <b-button
                type="is-text has-text-success"
                icon-left="plus"
                @click="addAnswer(q)"
              >
                Ajouter une réponse
              </b-button>
            </p>
          </div>
        </fieldset>
      </RenderlessToggle>
      <p class="buttons is-centered">
        <b-button
          icon-left="plus"
          :disabled="trainingItem.quiz_submissions_count > 0"
          @click="addQuestion">
          Ajouter une question
        </b-button>
      </p>
    </form>

    <AppSimpleModal
      class="has-text-dark"
      :active="contentForm != null"
    >
      <QuizMediaContentForm
        v-if="contentForm != null"
        :type="contentForm.type"
        :trainingItem="trainingItem"
        @done="addContent"
        @cancel="contentForm = null"
      />
    </AppSimpleModal>
  </div>
</template>

<style lang="scss" scoped>
.qgecomp {
  &_fieldset {
    border: 2px dashed $theme_color_primary;
  }

  ::v-deep &_explanation {
    min-height: 72px;
    height: 72px;
  }
}
</style>
