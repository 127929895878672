<script>
import cloneDeep from 'lodash/cloneDeep';
import { generateId } from '@shared/services/Utils';
import AppSimpleModal from '@shared/components/AppSimpleModal.vue';
import QuizAnswerMediaContent from '@shared/components/QuizAnswerMediaContent.vue';
import QuizMediaContentForm from '@app/components/QuizMediaContentForm.vue';

export default {
  components: {
    QuizAnswerMediaContent,
    QuizMediaContentForm,
    AppSimpleModal,
  },
  props: {
    trainingItem: {
      type: Object,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
    answer: {
      type: Object,
      required: true,
    },
    answerIndex: {
      type: Number,
      required: true,
    },
    vAnswer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contentFormType: null,
      hasReasonOpen: !!this.answer.answer_reason,
    };
  },
  computed: {
    field() {
      return this.question.feature_options.type === 'single'
        ? 'b-radio' : 'b-checkbox';
    },
    name() {
      return this.question.feature_options.type === 'single'
        ? `ans-${this.questionIndex}`
        : `ans--${this.questionIndex}-${this.answerIndex}`;
    },
  },
  methods: {
    openContentForm(type) {
      if (this.$store.getters['auth/hasPercentPlan']) {
        this.$showMessage.goPro(() => {
          this.$router.push({ name: 'plan', query: { onglet: 'vous' } });
        });
        return;
      }

      if (this.answer.content.length > 0) {
        this.$buefy.dialog.alert({
          title: 'Veuillez supprimer le média précédent',
          message: `
            Une réponse de quiz ne peut avoir qu'un
            seul média qui lui est associé.
          `,
        });
        return;
      }

      this.contentFormType = type;
    },
    addContent(data) {
      if (!this.contentFormType) {
        return;
      }

      const content = [{
        id: generateId(),
        type: this.contentFormType,
        data,
      }];

      this.contentFormType = null;
      this.updateAnswer('content', content);
    },
    removeContent() {
      this.updateAnswer('content', []);
    },
    onCorrectChange(value) {
      this.updateAnswer('is_correct', value);
      this.$emit('correct-change');
    },
    updateAnswer(key, value) {
      this.$emit('update:answer', { ...cloneDeep(this.answer), [key]: value });
    },
  },
};
</script>

<template>
  <div class="media is-align-items-flex-start pb-5 bbottom-grey">
    <div class="media-left mr-0">
      <b-button
        class="handle valign-middle mr-3"
        type="is-text"
        icon-left="grip-lines"
        size="is-medium"
      />
      <b-tooltip
        label="Cocher pour dire que c'est la bonne réponse"
        position="is-right"
      >
        <component
          :is="field"
          class="valign-middle"
          :name="name"
          :native-value="true"
          :value="answer.is_correct"
          @input="onCorrectChange"
        />
      </b-tooltip>
    </div>
    <div class="media-content mb-0">
      <b-field v-bind="$getErrorProps(vAnswer, ['required'])">
        <b-input
          maxlength="255"
          placeholder="Entrez une réponse"
          :has-counter="false"
          :value="answer.answer"
          @input="updateAnswer('answer', $event);"
          @blur="vAnswer.$touch()"
        />
      </b-field>
      <b-field
        v-show="hasReasonOpen"
        label="Explication de la réponse"
      >
        <b-input
          type="textarea"
          size="is-small"
          custom-class="qgecomp_explanation"
          maxlength="255"
          placeholder="Entrez une explication de la réponse (affichée à l'apprenant après soumission du quiz)"
          :has-counter="false"
          :value="answer.answer_reason"
          @input="updateAnswer('answer_reason', $event);"
        />
      </b-field>
      <QuizAnswerMediaContent
        class="field has-text-right"
        :answer="answer"
      >
        <template #delete>
          <b-button
            class="has-text-danger pr-0"
            type="is-text"
            @click="removeContent"
          >
            Supprimer
          </b-button>
        </template>
      </QuizAnswerMediaContent>
    </div>
    <div class="media-right ml-0">
      <b-dropdown
        :disabled="trainingItem.quiz_submissions_count > 0"
        position="is-bottom-left"
      >
        <template #trigger>
          <b-button
            class="ml-2"
            type="is-text"
            icon-left="ellipsis-h"
          />
        </template>
        <b-dropdown-item
          @click="openContentForm('image')"
        >
          <b-icon icon="image" />
          Ajouter une image
        </b-dropdown-item>
        <b-dropdown-item
          @click="openContentForm('video')"
        >
          <b-icon icon="video" />
          Ajouter une vidéo
        </b-dropdown-item>
        <b-dropdown-item
          @click="openContentForm('audio')"
        >
          <b-icon icon="microphone" />
          Ajouter un audio
        </b-dropdown-item>
        <b-dropdown-item v-if="!hasReasonOpen" separator />
        <b-dropdown-item v-if="!hasReasonOpen" @click="hasReasonOpen = true">
          <b-icon icon="spell-check" />
          Ajouter une explication
        </b-dropdown-item>
        <b-dropdown-item separator />
        <b-dropdown-item
          class="has-text-danger"
          @click="$emit('remove', answer)"
        >
          Supprimer
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <AppSimpleModal
      class="has-text-dark"
      :active="contentFormType != null"
    >
      <QuizMediaContentForm
        v-if="contentFormType != null"
        :type="contentFormType"
        :trainingItem="trainingItem"
        @done="addContent"
        @cancel="contentFormType = null"
      />
    </AppSimpleModal>
  </div>
</template>
